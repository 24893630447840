/* Services Section */
.services-section {
  padding: 40px;
  text-align: center;
  max-width: 1400px;
  margin: 0 auto;
}

/* Title styling remains the same */
.services-title {
  font-size: clamp(2rem, 1.5vw, 2.8rem);
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif;
  color: #03006d; /* Light blue color */
  text-align: center; /* Ensure the title is always centered */
  direction: ltr; /* Default to LTR */
}

/* RTL Support */
.rtl .services-title {
  direction: rtl; /* Switch direction for Arabic */
  text-align: center; /* Keep the title centered in RTL */
}

/* Container for the cards */
.services-cards {
  display: flex; /* Use flexbox for horizontal alignment */
  justify-content: center; /* Center the cards */
  flex-wrap: wrap; /* Allow wrapping on smaller screens */
  gap: 20px; /* Add spacing between cards */
  padding: 0 20px; /* Optional padding */
}

/* Adjustments for Services Cards to Act Similar to Why Us Cards */
.service-card {
  padding: 30px; /* Increase padding similar to Why Us cards */
  background-color: #f9f9f9;
  border-radius: 12px; /* Match border-radius with Why Us cards */
  width: 180px; /* Set width similar to Why Us cards */
  margin: 5px auto; /* Center the card by setting margins to auto */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transition for hover effects */
  text-align: left; /* Align text to left for consistency with Why Us section */
  min-height: 320px; /* Set minimum height to make cards taller */
  display: flex;
  flex-direction: column; /* Ensure content is vertically aligned */
  align-items: center;
  position: relative; /* Needed for icon positioning */
}

/* Hover effect for the Services cards */
.service-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25); /* Increase shadow on hover for more emphasis */
}

/* Icon styling adjustments for consistency */
.service-icon {
  width: 60px; /* Adjust size to match Why Us icons */
  height: 60px; /* Adjust size to match Why Us icons */
  margin-bottom: 15px;
  min-width: 0px; /* Ensure a minimum width for the images */
}

/* Font styling for the text inside the cards */
.service-title {
  font-size: clamp(1rem, 2vw, 1.5rem); /* Match font size with Why Us cards */
  font-weight: bold;
  color: #03006d; /* Match color with Why Us section details */
  margin-top: 70px; /* Add space for the icon */
  margin-bottom: 10px;
  text-align: center; /* Ensure title text is centered */
  font-family: "Inter", sans-serif; /* Use the same font as the description text */
}

.service-description {
  font-size: clamp(1rem, 2vw, 1.5rem); /* Match font size with Why Us cards */
  color: #444; /* Darker color for more contrast */
  font-family: "Inter", sans-serif; /* Default English font */
  line-height: 1.6; /* Slightly increase line-height for readability */
  text-align: center; /* Ensure description text is centered */
  white-space: normal; /* Ensure text wraps */
}

/* RTL Support */
.rtl .service-card {
  text-align: center; /* Align text to right for Arabic */
}

.rtl .service-title,
.rtl .service-description {
  text-align: center; /* Align title and description to right for Arabic */
}
