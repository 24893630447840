/* ContentSection.css */
.content-section {
  background-color: #ffffff; /* White background */
  padding: 20px 20px; /* Adjusted padding */
  font-family: "Inter", sans-serif;
}

.content-title {
  font-size: clamp(2rem, 1.5vw, 2.8rem);
  font-weight: bold;
  margin-bottom: 20px; /* Match margin with Services Section */
  color: #03006d; /* Match the title color in Services Section */
  text-align: center; /* Center the title */
}

.content-paragraph {
  font-size: clamp(
    1rem,
    2vw,
    1.5rem
  ); /* Match font size with Services Section */
  color: #666; /* Match color with Service Description */
  line-height: 1.5; /* Match line-height with Services Section */
  margin-bottom: 20px; /* Match margin with Services Section */
  text-align: justify;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
}

.rtl .content-section {
  direction: rtl;
  text-align: right;
}

.rtl .content-title,
.rtl .content-paragraph {
  font-family: "Noto Kufi Arabic", sans-serif !important;
}

/* Media Queries */
@media (max-width: 768px) {
  .content-title {
    font-size: clamp(1.8rem, 4vw, 2.5rem); /* Adjusted title size for mobile */
  }

  .content-paragraph {
    font-size: clamp(
      1rem,
      2.5vw,
      1.5rem
    ); /* Adjusted paragraph size for mobile */
  }

  .horizontal-line {
    max-width: 100%; /* Allows full width on smaller screens */
  }
}

/* ContentSection.css */
.content-section {
  background-color: #ffffff; /* White background */
  padding: 20px 20px; /* Adjusted padding */
  font-family: "Inter", sans-serif;
}

.content-title {
  font-size: clamp(
    2rem,
    2.5vw,
    2.8rem
  ); /* Match font size with Services Section */
  font-weight: bold;
  margin-bottom: 20px; /* Match margin with Services Section */
  text-align: center; /* Center the title */
}

.content-paragraph {
  font-size: clamp(
    1rem,
    2vw,
    1.5rem
  ); /* Match font size with Services Section */
  color: #666; /* Match color with Service Description */
  line-height: 1.5; /* Match line-height with Services Section */
  margin-bottom: 20px; /* Match margin with Services Section */
  text-align: justify;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
}

.rtl .content-section {
  direction: rtl;
  text-align: right;
}

.rtl .content-title {
  text-align: center; /* Ensure the title is centered in RTL */
}

.rtl .content-paragraph {
  text-align: right; /* Right-align the paragraph text in RTL */
  font-family: "Noto Kufi Arabic", sans-serif !important;
}

/* Media Queries */
@media (max-width: 768px) {
  .content-title {
    font-size: clamp(1.8rem, 4vw, 2.5rem); /* Adjusted title size for mobile */
  }

  .content-paragraph {
    font-size: clamp(
      1rem,
      2.5vw,
      1.5rem
    ); /* Adjusted paragraph size for mobile */
  }

  .horizontal-line {
    max-width: 100%; /* Allows full width on smaller screens */
  }

  .rtl .content-paragraph {
    text-align: justify; /* Justify text on smaller screens in RTL */
  }
}
