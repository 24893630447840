.expertise-section {
  padding: 40px; /* Increased padding for more space */
  font-family: "Inter", sans-serif;
  max-width: 1200px;
  margin: 0 auto;
  border-radius: 8px; /* Add rounded corners to the section */
  text-align: center; /* Center the title */
}

.expertise-title {
  font-size: clamp(2rem, 1.5vw, 2.8rem);
  font-weight: bold;
  color: #03006d; /* Consistent color scheme */
  margin-bottom: 40px; /* Increased margin for more space between title and points */
}

.expertise-items {
  display: flex;
  flex-wrap: wrap; /* Allows items to wrap to the next line if necessary */
  gap: 20px; /* Space between each item */
  justify-content: center; /* Center items in the container */
  padding-left: 0; /* Remove padding that might cause bullet points */
  list-style-type: none; /* Remove bullet points */
}

.expertise-item {
  background-color: #ffffff; /* White background for the boxes */
  border-radius: 8px; /* Rounded corners for the boxes */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  padding: 20px; /* Padding inside each box */
  width: calc(45%); /* Three items per row, adjust to fit */
  margin-bottom: 20px; /* Space between boxes */
  text-align: left; /* Default to left-aligned text */
  font-size: 1.3rem; /* Increased font size */
}

.rtl .expertise-item {
  text-align: right; /* Right-align text within each box for RTL */
}

/* RTL Support */
.rtl .expertise-title {
  direction: rtl;
}

.rtl .expertise-items {
  direction: rtl;
  padding-right: 0; /* Remove padding for RTL */
}

.arabic-text {
  font-family: "Noto Kufi Arabic", sans-serif !important;
}

/* Responsive Layout */
@media (max-width: 1024px) {
  .expertise-item {
    width: calc(50% - 20px); /* Two items per row on medium screens */
  }
}

@media (max-width: 768px) {
  .expertise-items {
    flex-direction: column; /* Stack items vertically on small screens */
    padding-left: 10px;
    padding-right: 10px; /* Add padding for RTL support */
  }

  .expertise-item {
    width: 100%; /* Full width for each item on small screens */
    font-size: 1.5rem; /* Slightly reduced font size for smaller screens */
  }
}
