/* Why Us Section */

/* Container for the Why Us section */
.why-us-section {
  padding: 40px;
  text-align: center;
  max-width: 1380px;
  margin: 0 auto;
}

/* Title of the Why Us section */
.why-us-title {
  font-size: clamp(2rem, 1.5vw, 2.8rem);
  font-weight: bold;
  margin-bottom: 20px;
  font-family: "Inter", sans-serif; /* Default English font */
  color: #03006d; /* Match the title color with Hero Section */
  text-align: center; /* Center the title */
}

/* Cards container for desktop layout */
.why-us-cards {
  display: flex;
  justify-content: center; /* Center the cards container */
  flex-wrap: wrap;
  gap: 20px; /* Add spacing between cards */
}

/* Individual card styling */
.why-us-card {
  background-color: #f0f4f8;
  padding: 30px; /* Increase padding for more visual weight */
  margin: 10px auto; /* Center the card by setting margins to auto */
  border-radius: 12px; /* Increase border-radius for a more pronounced shape */
  width: 250px; /* Slightly increase width for more presence */

  transition: transform 0.3s ease, box-shadow 0.3s ease;
  text-align: left; /* Align text to left for consistency with Hero Section */
  min-height: 320px; /* Set minimum height to make cards taller */
  display: flex;
  flex-direction: column; /* Ensure content is vertically aligned */
  position: relative; /* Needed for icon positioning */
}

/* Hover effect for the cards */
.why-us-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25); /* Increase shadow on hover for more emphasis */
}

.why-us-card .why-us-icon {
  width: 60px; /* Increase size for more visual impact */
  height: 60px; /* Increase size for more visual impact */
  padding: 12px;
  background-color: rgba(
    255,
    235,
    59,
    0.4
  ); /* Increase opacity for bolder icons */
  border-radius: 10px; /* Increase border-radius to match card's shape */
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Color adjustments for individual icons */
.why-us-card.icon1 .why-us-icon {
  background-color: rgba(255, 235, 59, 0.4); /* Yellow with increased opacity */
}

.why-us-card.icon2 .why-us-icon {
  background-color: rgba(244, 67, 54, 0.4); /* Red with increased opacity */
}

.why-us-card.icon3 .why-us-icon {
  background-color: rgba(76, 175, 80, 0.4); /* Green with increased opacity */
}

.why-us-card.icon4 .why-us-icon {
  background-color: rgba(33, 150, 243, 0.4); /* Blue with increased opacity */
}

/* Icon positioning adjustments for Arabic text */
.why-us-icon.arabic-text {
  right: 15px; /* Position at top right for Arabic */
}

.why-us-icon:not(.arabic-text) {
  left: 15px; /* Position at top left for English */
}

/* Font styling for the text inside the cards */
.why-us-card-title {
  font-size: clamp(1.5rem, 1vw, 2.5rem);
  font-weight: bold;
  color: #03006d; /* Match color with Hero Section details */
  margin-top: 70px; /* Add space for the icon */
  font-family: "Inter", sans-serif; /* Default English font */
}

.why-us-card-description {
  font-size: clamp(
    1.2rem,
    1vw,
    1.5rem
  ); /* Match font size with Services Section */
  color: #444; /* Darker color for more contrast */
  font-family: "Inter", sans-serif; /* Default English font */
  line-height: 1.6; /* Slightly increase line-height for readability */
  white-space: normal; /* Ensure text wraps */
}

/* Arabic text styling */
.arabic-text {
  font-family: "Noto Kufi Arabic", sans-serif;
  direction: rtl;
  text-align: middle;
}

/* Responsive Mobile Layout */
@media (max-width: 786px) {
  .why-us-cards {
    flex-direction: column; /* Stack cards vertically */
    align-items: center; /* Center-align cards */
  }

  .why-us-card {
    width: 90%; /* Full width with padding on mobile */
    max-width: 400px; /* Ensure max width on mobile */
    min-height: 250px; /* Adjust the minimum height for mobile */
    margin-left: auto; /* Center card */
    margin-right: auto; /* Center card */
    padding-left: 10px; /* Reduce left padding */
    padding-right: 10px; /* Ensure balance with right padding */
  }

  .why-us-title {
    font-size: clamp(
      1.8rem,
      4vw,
      2.5rem
    ); /* Match font size with Services Section */
  }

  .why-us-text {
    font-size: clamp(
      1rem,
      2.5vw,
      1.5rem
    ); /* Match text size with Services Section */
  }
}

/* RTL Support */
.rtl .why-us-card {
  text-align: right; /* Align text to right for Arabic */
}

/* Arabic text styling */
.arabic-text {
  font-family: "Noto Kufi Arabic", sans-serif;
  direction: rtl;
  text-align: right; /* Ensure proper text alignment */
}

.why-us-title.arabic-centered {
  text-align: center; /* Center the text horizontally */
  direction: rtl; /* Maintain RTL direction */
  font-family: "Noto Kufi Arabic", sans-serif; /* Apply Arabic font */
}
