.logo-carousel {
  overflow: hidden;
  width: 100%;
  max-width: 1300px; /* Matches the Hero section's max width */
  margin: auto; /* Center the carousel */
  padding: 10px 0; /* Default padding for desktop */
  margin-bottom: 20px; /* Add bottom margin for spacing */
}

.logo-carousel-wrapper {
  display: flex;
  animation: scroll 30s linear infinite; /* Increase the speed of the animation */
  width: calc(
    200%
  ); /* Ensure that the content is double the width for seamless looping */
}

.logo-carousel-icon {
  height: 80px; /* Adjust the height to fit your design */
  margin-right: 20px; /* Adjust spacing between logos */
  margin-bottom: 10px; /* Add bottom margin for spacing */
  object-fit: contain;
}

/* Keyframes for continuous scrolling effect */
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-50%); /* Move left by 50% of the total width */
  }
}

/* Mobile-specific adjustments */
@media (max-width: 768px) {
  .logo-carousel {
    padding: 15px 0; /* Add some padding on the top and bottom for mobile */
    margin-bottom: 15px; /* Adjust bottom margin for mobile */
  }

  .logo-carousel-wrapper {
    animation: scroll-mobile 10s linear infinite; /* Faster animation for mobile */
  }

  /* Faster keyframes for mobile */
  @keyframes scroll-mobile {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-50%); /* Move left by 50% of the total width */
    }
  }

  .logo-carousel-icon {
    height: 60px; /* Slightly smaller height for mobile */
    margin-right: 15px; /* Adjust spacing for mobile */
    margin-bottom: 8px; /* Add bottom margin for spacing */
  }
}
