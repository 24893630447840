.vision-section {
  padding: 20px;
  background-color: #ffffff; /* White background */
  font-family: "Inter", sans-serif;
  max-width: 1300px;
  margin: 0 auto;
}

.vision-title-main {
  font-size: clamp(1.2rem, 1vw, 1.5rem);
  font-weight: 700;
  color: #1a1a1a; /* Darker shade for the main title */
  margin-bottom: 20px;
  text-align: center; /* Center the title */
  direction: ltr; /* Reset direction for centering */
}

.vision-content {
  max-width: 100%;
  margin: 0 auto;
}

.vision-item {
  margin-bottom: 20px;
  padding: 15px;
  background-color: #f9f9f9; /* Very light gray background for subtle contrast */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
  transition: box-shadow 0.3s ease; /* Smooth transition for hover effect */
}

/* Hover effect for the cards */
.vision-item:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25); /* Increase shadow on hover for more emphasis */
}

.vision-title {
  font-size: 2rem; /* Slightly increased font size */
  font-weight: 700;
  color: #03006d; /* A readable dark shade for the title */
  text-align: left; /* Left-align the section title */
}

.vision-description,
.vision-additional-info {
  font-size: 1.2rem; /* Slightly increased font size */
  color: #4a4a4a; /* Darker gray for better readability */
  margin-top: 10px;
  line-height: 1.6;
  text-align: left; /* Left-align the description */
}

.arabic-text {
  font-family: "Noto Kufi Arabic", sans-serif !important;
  text-align: right; /* Align Arabic text to the right */
}

.rtl .vision-title-main {
  direction: rtl; /* Set direction for Arabic */
  text-align: center; /* Center the title for Arabic as well */
}

.rtl .vision-item {
  text-align: right; /* Right-align content in Arabic */
}

.rtl .vision-description,
.rtl .vision-additional-info {
  text-align: right; /* Right-align the description in Arabic */
}
