/* Desktop Styles */
.desktop-hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px 0;
  font-family: "Inter", sans-serif;
  position: relative;
  overflow: hidden;
}

.desktop-banner-v2-content-wrap {
  display: flex;
  max-width: 1300px;
  max-height: 600px;
  border-radius: 10px;
  position: relative;
  padding: 0;
  z-index: 2;
}

.desktop-banner-v2-details-wrap {
  flex: 1;
  padding: 50px;
  position: relative;
}

.desktop-banner-title-v2-big,
.desktop-banner-title-v2-small,
.desktop-banner-details-v2 {
  text-align: left;
  margin: 0;
}

.desktop-banner-title-v2-big {
  font-size: clamp(2rem, 1.5vw, 2.8rem);
  font-weight: 700;
  color: #03006d;
  font-family: "Inter", sans-serif;
  line-height: 1.1;
  white-space: normal;
}

.desktop-banner-title-v2-small {
  font-size: clamp(1.5rem, 1.2vw, 2rem);
  margin-top: 10px;
  color: #c53538;
  font-family: "Inter", sans-serif;
  line-height: 1.2;
  white-space: normal;
}

.desktop-banner-details-v2 {
  margin: 20px 0;
  font-size: clamp(1.2rem, 1.2vw, 1.5rem);
  color: #1a1a1a;
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  white-space: normal;
}

.desktop-banner-btns-wrapper {
  margin-top: 30px;
}

.desktop-primary-button {
  background-color: #03006d;
  color: white;
  padding: 12px 30px;
  text-decoration: none;
  border-radius: 30px;
  display: inline-block;
  font-size: clamp(1rem, 2vw, 1.25rem);
  font-family: "Inter", sans-serif;
  text-align: center; /* Default center alignment */
}

/* Image Wrappers */
.desktop-banner-v2-img-wrapper {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.desktop-banner-v2-main-img {
  max-width: 100%;
  max-height: 100%;
  height: auto;
  border-radius: 10px;
  z-index: 2;
  animation: FadeInUp 0.5s ease-in-out;
}

/* Arabic Text Styles */
.desktop-banner-title-v2-big.arabic-text,
.desktop-banner-title-v2-small.arabic-text,
.desktop-banner-details-v2.arabic-text,
.desktop-primary-button.arabic-text {
  font-family: "Noto Kufi Arabic", sans-serif !important;
  text-align: right;
}

.rtl .desktop-banner-btns-wrapper {
  justify-content: flex-end;
}

.desktop-primary-button.arabic-text {
  text-align: right; /* Ensure text is aligned to the right */
}

/* Media Queries */
@media (max-width: 1225px) {
  .desktop-banner-title-v2-big {
    font-size: 2rem;
  }

  .desktop-banner-title-v2-small {
    font-size: 1.5rem;
  }

  .desktop-banner-details-v2 {
    font-size: 1rem;
  }

  .desktop-primary-button {
    font-size: 1rem;
  }
}

@media (max-width: 786px) {
  .desktop-banner-title-v2-big {
    font-size: 2rem;
  }

  .desktop-banner-title-v2-small {
    font-size: 1.5rem;
  }

  .desktop-banner-details-v2 {
    font-size: 1rem;
  }

  .desktop-primary-button {
    font-size: 1rem;
  }
}

/* Button Zoom and Scroll Down on Hover */
.desktop-primary-button {
  transition: transform 0.3s ease-in-out;
}

.desktop-primary-button:hover {
  transform: scale(1.1) translateY(2px);
}

/* Image Zoom In While Maintaining Size and Scroll Down */
.desktop-banner-v2-img-wrapper {
  overflow: hidden;
}

@keyframes FadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.desktop-banner-v2-img-left {
  order: 1;
}

.desktop-banner-v2-img-right {
  order: 1;
}

.button-align-right {
  text-align: right;
}

/* Mobile Styles */
.mobile-hero-section {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  font-family: "Noto Kufi Arabic", sans-serif;
  background-color: #f8f8f8;
}

.mobile-banner-img-wrapper {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;
  height: auto; /* Adjust height to be auto, so it only takes up necessary space */
}

.mobile-banner-img {
  max-width: 160%;
  width: auto;
  z-index: 1;
  object-fit: cover;
}

.mobile-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 2;
}

.mobile-text-overlay {
  position: absolute;
  top: 20px;
  z-index: 3;
  color: white;
  padding: 20px;
}

.mobile-banner-title-v2-big {
  font-size: clamp(1.5rem, 2.5vw, 2rem);
  font-weight: 700;
  color: #ffffff;
  line-height: 1.2;
  margin: 0;
}

.mobile-banner-title-v2-small {
  font-size: clamp(1.2rem, 2vw, 1.5rem);
  color: #ffffff;
  margin-top: 10px;
}

.mobile-banner-details-v2 {
  font-size: clamp(
    0.9rem,
    1.4vw,
    1.1rem
  ); /* Smaller font to fit within two lines */
  color: #eaeaea;
  margin-top: 15px;
  max-width: 90%; /* Ensures text wraps within two lines */
  line-height: 1.3; /* Adjust line-height for better spacing */
  white-space: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* Limit to 2 lines */
  -webkit-box-orient: vertical;
}

.mobile-banner-btns-wrapper {
  margin-top: 20px;
  text-align: center; /* Ensure the button is centered */
}

.mobile-primary-button {
  background-color: #03006d;
  color: white;
  padding: 8px 20px; /* Smaller padding for a smaller button */
  text-decoration: none;
  border-radius: 20px; /* Adjust the border-radius */
  font-size: clamp(0.9rem, 1.8vw, 1rem); /* Smaller font size for the button */
  display: inline-block;
  transition: transform 0.3s ease-in-out;
}

.mobile-primary-button:hover {
  transform: scale(1.05) translateY(2px);
}

.arabic-text .mobile-banner-btns-wrapper {
  text-align: center; /* Ensure the button is centered for Arabic text */
}

.mobile-primary-button {
  z-index: 3;
}

.desktop-primary-button.arabic-text {
  text-align: right;
}
